.form-wrapper {
  margin-top: 2.5rem;
}

.settings-subtitle {
  padding-left: 17%;
  margin-top: 1rem;
  color: #949698;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.settings-credentials-info {
  padding-left: 26%;
  margin-bottom: 1rem;
}

.settings-credentials-info h6 {
  font-weight: 400;
  font-size: 15px;
  color: #333333;
}

.settings-credentials-info a {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.settings-credentials-info a svg {
  font-size: 16px;
  margin-left: 5px;
}

.form-wrapper .form-group {
  margin-bottom: 1rem;
}

.input-action-btn {
  color: #2979ff;
  cursor: pointer;
  margin-top: 4px;
  text-decoration: underline;
  display: flex;
  justify-content: right;
  font-size: 14px;
}

.input-action-btn:hover {
  color: blue;
}

.password-visibility .form-control {
  padding-right: 2.5rem;
}

.input-action-buttons {
  float: right;
}

.input-action-buttons button {
  margin-left: 5px;
  padding: 3px 6px;
  margin-top: 1px;
}

.input-action-buttons button svg {
    width: 1.5em;
    height: 1.5em;
}

.api-key-copy-button button {
  font-size: 15px;
}

.api-key-copy-button button svg {
  vertical-align: -3px;
  margin-right: 2px;
}

.api-key-copy-button {
  display: none;
  position: absolute;
  right: calc(var(--bs-gutter-x)/ 2);
}

svg.api-copied {
  fill: #90ee90;
}

.password-visibility:hover .api-key-copy-button{
  display: inline-block;
}

/* Settings page */

.settingButtonSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.setButton {
  width: 20%;
}

