.css-1ihdu9r-icon {
  border-width: 0.2em 0.2em 0 0 !important;
  height: 0.7em !important;
  width: 0.7em !important;
  right: 1.5rem !important;
}

.nav-logo {
  margin-right: 10px;
}

.nav-icon-project-select-wrapper {
  display: flex;
  align-items: center;
}

.nav-icon-project-select-box {
  width: auto;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 14px;
  padding-bottom: 11px;
  background-color: #1d2125;
}

.nav-icon-project-add-new-project {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #1d2125;
  border-right: 2px solid #141414;
}

.second-nav {
  background-color: #1d2125 !important;
}

.main-nav {
  background-color: #1d2125 !important;
}

.main-nav-links-wrapper {
  display: flex;
  position: relative;
}

.main-nav-links {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
}

.new-project-message {
  margin-top: -1rem;
  margin-bottom: 1rem;
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 3px;
  display: flex;
  gap: 0.8rem;
  align-items: flex-start;
}

.new-project-message svg {
  min-width: 27px;
  height: auto;
  display: block;
  color: #00bcd4;
}

.dropdown-menu {
  z-index: 2000;
}

.id-verify-alert {
  display: flex;
  flex-direction: column;
  background: #F2C94C;
  padding: 6px 16px;
  color: #333333;
}

.id-verify-message {
  width: 1320px;
  margin-right: auto;
  margin-left: auto;
}

.id-verify-button .btn {
  padding: 5px 0.75rem;
  font-size: 14px;
}

.user-active-state {
  color: #2979ff;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
  cursor: default;
}

.remove-uploads {
  background: transparent;
  border: 0;
  color: #0d6efd;
  padding: 6px;
}

.doc-error {
  color: #dc3545;
  font-size: 14px;
}

.verify-upload-error {
  color: #dc3545;
  font-size: 14px;
}

.error-red {
  color: #dc3545;
}

.doc-image-preview {
  width: 90px;
  max-height: 100px;
  object-fit: fill;
  margin-top: 10px;
  margin-right: 10px;
}

.docs-upload-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.uploading-docs-progressbar .progress {
  width: 125px;
}

.uploading-docs-progressbar .progress {
  height: 0.4rem;
}

.remove-uploads:hover {
  text-decoration: underline;
}

.state-not-verified {
  color: #842029;
}

.user-info-wrapper .btn-link {
  text-decoration: none;
  font-size: 14px;
}

.main-nav-container {
  position: relative;
}

.user-info-wrapper .btn-link:hover {
  text-decoration: underline;
}

.id-verification-wrapper {
  margin-top: 2rem;
  font-size: 15px;
}

.pending-verify-note {
  padding: 1rem;
  background-color: #e3f2fd;
  border-radius: 3px;
  font-size: 15px;
}

.form-control-file {
  color: #828282;
}

.pending-item-info {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.pending-item-title {
  font-weight: 500;
  margin-right: 10px;
}

.verify-pending-list i {
  margin-right: 7px;
  margin-top: -1px;
}

.verify-info-list {
  margin-bottom: 2rem;
}

.data-checking-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.new-project-message p {
  margin-bottom: 0;
}

.active-nav-link {
  color: #ffffff !important;
  font-weight: 600;
}

.main-nav-links .nav-link {
  color: rgb(255 255 255 / 78%) !important;
}

.nav-create-project-wrapper {
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.nav-create-project-wrapper:hover {
  text-decoration: underline;
}

.nav-create-project-plus-icon {
  margin-right: 5px;
}

.nav-create-project-plus-icon svg {
  vertical-align: text-top;
}

.nav-create-project-add {
  white-space: nowrap;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  background-color: #f7f7f7;
}

.login-field {
  border-color: #8ed6ff !important;
}

.login-field:focus {
  border-color: #2979ff !important;
}

.custom-new-project-input {
  border: none;
  padding-left: 0;
  font-size: 1.5rem;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  border-color: #ced4da !important;
}

.nav-logo img {
  max-width: 134px;
  max-height: 48px;
}

.go-back-link {
  margin-bottom: 10px;
  display: inline-block;
  color: #000000;
}

.form-group.row .col-form-label {
  text-align: right;
  padding-top: calc(0.6rem + 1px);
  font-size: 14px;
}

.custom-form-control {
  background-color: #f0f0f0;
  border-color: transparent !important;
  padding: 0.4rem 0.75rem;
  font-size: 0.9rem;
  color: #333333;
}

.custom-form-control:focus {
  border-color: #e0e0e0 !important;
  background: #f0f0f0 !important;
}

.custom-form-control-dropdown {
  background-color: #f0f0f0 !important;
  width: 100%;
  border-color: transparent !important;
  padding: 0.4rem 0.75rem;
  font-size: 0.9rem;
  color: #333333;
}

.custom-form-control-dropdown:focus {
  border-color: #e0e0e0 !important;
  background: #f0f0f0 !important;
}

.form-control:disabled,
.form-control:read-only {
  background-color: #f0f0f0;
}

.password-visibility {
  position: relative;
}

.password-visibility .show-hide-password-button {
  position: absolute;
  top: 14px;
  right: 25px;
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
}

.password-visibility .show-hide-password-button svg {
  vertical-align: baseline;
}

.password-visibility .show-hide-password-button:hover svg {
  fill: blue;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67vh;
}

.css-1szzzam-menuItem-menuItemActive {
  background: #2979ff !important;
  color: #ffffff !important;
}

.css-1szzzam-menuItem-menuItemActive:hover {
  background: #0960f8 !important;
}

.css-1k8n018-menuItem:hover {
  background: #ffffff !important;
}

.user-account-info-popup {
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  outline: none;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 45px;
  animation: gb__a 0.2s;
  border-radius: 2px;
  -webkit-user-select: text;
  z-index: 1001;
  min-width: 227px;
  padding: 1rem 1rem;
  text-align: center;
  border-radius: 5px;
}

.user-info-wrapper h6 {
  color: #202124;
  letter-spacing: 0.29px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-info-wrapper p {
  color: #5f6368;
  letter-spacing: normal;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  margin-bottom: 5px;
}

.confirm-action-dialog .modal-header svg {
  font-size: 2.5rem;
  color: #dc3545;
}

.confirm-action-dialog .modal-header {
  display: block;
  text-align: center;
}

.confirm-action-dialog .modal-header h4 {
  margin-top: 1rem;
  color: #dc3545;
  font-size: 20px;
  font-weight: 500;
}

.confirm-action-dialog .modal-body {
  background-color: #f8f8f8;
}

.confirm-action-dialog .modal-body .confirm-dialog-meg {
  margin: 1rem 0 1.5rem 0;
  text-align: center;
}

.action-buttons-center button {
  margin: 0 5px;
  min-width: 90px;
}

.action-buttons-center {
  text-align: center;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: none;
  border: none;
}

.no-projects {
  color: grey;
  height: 36px;
}

.no-projects .new-project-button {
  background-color: transparent;
  border: 0;
}

.no-projects .new-project-button svg {
  vertical-align: -2px;
  margin-right: 2px;
}

.no-projects .new-project-button:hover {
  text-decoration: underline;
}

.projects-loading-spinner {
  padding-left: 3rem;
  padding-right: 3rem;
}

.nav-icon-project-select-box > .dropdown .dropdown-toggle {
  color: white;
  border-color: #3c64a7;
  border-width: 2px;
  background: #4f4f4f;
  font-weight: 600;
  min-width: 100px;
}

.nav-icon-project-select-box > .dropdown .dropdown-toggle::after {
  margin-left: 1.255em;
}

.un-selected-item {
  color: #666666;
}

.selected-item {
  color: #333333;
}

.nav-hr {
  color: white;
  /* margin: 0 15% 0 14.5%; */
  max-width: 1312px;
  margin: auto;
}

.nav-bar-main-div {
  background: #1d2125;
}

.page-size-selecter {
  margin-left: 5px;
}

.data-details {
  display: flex;
  justify-content: space-between;
}

.api-hr {
  color: white;
  margin: 0 5% 0 5%;
}

@media only screen and (max-width: 1400px) {
  .nav-hr {
    max-width: 1130px;
    margin: auto;
  }
}

.main-container {
  padding-right: 0;
}

@media only screen and (max-width: 1200px) {
  .nav-hr {
    max-width: 950px;
    margin: auto;
  }

  .main-container {
    padding-right: 0;
  }
}

@media only screen and (max-width: 990px) {
  .nav-hr {
    max-width: 714px;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .form-group.row .col-form-label {
    text-align: left;
  }
}

@media only screen and (max-width: 369px) {
  .user-account-info-popup {
    left: -64px;
  }

  .navbar-expand-md .main-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .nav-icon-project-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
  }

  .nav-icon-project-select-box {
    margin-top: 10px;
  }

  .navbar > .container {
    align-items: baseline;
  }

  .page-secondary-header {
    flex-direction: column;
  }
}

@media only screen and (max-width: 576px) {
  .main-container {
    padding-left: 0;
  }
}
