@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Ubuntu", sans-serif !important;
  background: #d9effb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo-col {
  background-color: #040d21;
}

.login-reg-col {
  background: #fff;
}

.logo-col .logo-back-div {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo-col .logo-back-div .logo {
  display: block;
  width: 175px;
  margin: auto;
  margin-bottom: 30px;
}

.logo-col .logo-back-div .heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 35px;
  color: #e8f0fe;
  letter-spacing: 0.7px;
}

.logo-col .feature-table {
  width: 345px;
  margin: auto;
}

.logo-col .feature-table tr td {
  padding: 10px;
  font-size: 18px;
}

.logo-col .feature-table tr td:nth-child(even) {
  color: #e8f0fe;
}

.logo-col,
.login-reg-col {
  height: 100vh;
  position: relative;
}

.logo-col .feature-table .feature-icon {
  font-size: 25px;
  color: #32acf1;
  vertical-align: middle;
}

@media (max-width: 380px) {
  .logo-col .feature-table {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .logo-col {
    height: 570px;
    order: 2;
  }

  .login-reg-col {
    min-height: 530px;
    height: unset;
    order: 1;
  }
}

@media (max-width: 1200px) {
  .logo-col .logo-back-div {
    width: 90%;
  }
}

.login-reg-container {
  width: 75%;
  max-width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-reg-container .heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  color: #033d5e;
  letter-spacing: 0.7px;
  padding: 0 15px;
}

@media (max-width: 480px) {
  .login-reg-container {
    width: 90%;
  }
}

.btn-submit {
  display: block;
  width: 100%;
  background: #2979FF;
  padding: 7px 15px;
  color: #fff;
  text-align: center;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 25px;
  font-weight: 500;
}

.btn-delete {
  display: block;
  width: 100%;
  background: #dc3545;
  padding: 7px 15px;
  color: #fff;
  text-align: center;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 25px;
  font-weight: 500;
}

.btn-submit:hover {
  background: #075f92;
  color: #fff;
}

.btn-submit:focus {
  box-shadow: none;
  outline: 0;
}

.btn-cancel {
  display: block;
  width: 100%;
  background: transparent;
  padding: 6px 15px;
  color: #4c4c4c;
  text-align: center;
  border-width: 1px;
  border-color: #2979FF;
  border-radius: 0.25rem;
  margin-top: 25px;
  font-weight: 500;
}

.btn-cancel:hover {
  background: #2979FF;
  color: #fff;
}

.btn-cancel:focus {
  box-shadow: none;
  outline: 0;
}

.form-bellow-link {
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.forget-password-link {
  display: block;
  font-size: 13px;
  text-align: center;
  margin-top: 15px;
  color: #1891d6;
  text-decoration: none;
}

.login-now-link {
  color: #1891d6;
  font-weight: 400;
  text-decoration: none;
}

.forget-password-link:hover,
.login-now-link:hover {
  color: #095f91;
  text-decoration: underline;
}

.forget-password-steps {
  color: #208ac5;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  display: block;
}

/* Login modal */
.lg-mdl-description {
  font-size: 1rem;
}

.auth-error {
  font-size: 0.9rem;
  color: #dc3545;
}
.css-1ihdu9r-icon {
  border-width: 0.2em 0.2em 0 0 !important;
  height: 0.7em !important;
  width: 0.7em !important;
  right: 1.5rem !important;
}

.nav-logo {
  margin-right: 10px;
}

.nav-icon-project-select-wrapper {
  display: flex;
  align-items: center;
}

.nav-icon-project-select-box {
  width: auto;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 14px;
  padding-bottom: 11px;
  background-color: #1d2125;
}

.nav-icon-project-add-new-project {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #1d2125;
  border-right: 2px solid #141414;
}

.second-nav {
  background-color: #1d2125 !important;
}

.main-nav {
  background-color: #1d2125 !important;
}

.main-nav-links-wrapper {
  display: flex;
  position: relative;
}

.main-nav-links {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
}

.new-project-message {
  margin-top: -1rem;
  margin-bottom: 1rem;
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 3px;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  align-items: flex-start;
}

.new-project-message svg {
  min-width: 27px;
  height: auto;
  display: block;
  color: #00bcd4;
}

.dropdown-menu {
  z-index: 2000;
}

.id-verify-alert {
  display: flex;
  flex-direction: column;
  background: #F2C94C;
  padding: 6px 16px;
  color: #333333;
}

.id-verify-message {
  width: 1320px;
  margin-right: auto;
  margin-left: auto;
}

.id-verify-button .btn {
  padding: 5px 0.75rem;
  font-size: 14px;
}

.user-active-state {
  color: #2979ff;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
  cursor: default;
}

.remove-uploads {
  background: transparent;
  border: 0;
  color: #0d6efd;
  padding: 6px;
}

.doc-error {
  color: #dc3545;
  font-size: 14px;
}

.verify-upload-error {
  color: #dc3545;
  font-size: 14px;
}

.error-red {
  color: #dc3545;
}

.doc-image-preview {
  width: 90px;
  max-height: 100px;
  object-fit: fill;
  margin-top: 10px;
  margin-right: 10px;
}

.docs-upload-info {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}
.uploading-docs-progressbar .progress {
  width: 125px;
}

.uploading-docs-progressbar .progress {
  height: 0.4rem;
}

.remove-uploads:hover {
  text-decoration: underline;
}

.state-not-verified {
  color: #842029;
}

.user-info-wrapper .btn-link {
  text-decoration: none;
  font-size: 14px;
}

.main-nav-container {
  position: relative;
}

.user-info-wrapper .btn-link:hover {
  text-decoration: underline;
}

.id-verification-wrapper {
  margin-top: 2rem;
  font-size: 15px;
}

.pending-verify-note {
  padding: 1rem;
  background-color: #e3f2fd;
  border-radius: 3px;
  font-size: 15px;
}

.form-control-file {
  color: #828282;
}

.pending-item-info {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.pending-item-title {
  font-weight: 500;
  margin-right: 10px;
}

.verify-pending-list i {
  margin-right: 7px;
  margin-top: -1px;
}

.verify-info-list {
  margin-bottom: 2rem;
}

.data-checking-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.new-project-message p {
  margin-bottom: 0;
}

.active-nav-link {
  color: #ffffff !important;
  font-weight: 600;
}

.main-nav-links .nav-link {
  color: rgb(255 255 255 / 78%) !important;
}

.nav-create-project-wrapper {
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.nav-create-project-wrapper:hover {
  text-decoration: underline;
}

.nav-create-project-plus-icon {
  margin-right: 5px;
}

.nav-create-project-plus-icon svg {
  vertical-align: text-top;
}

.nav-create-project-add {
  white-space: nowrap;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  background-color: #f7f7f7;
}

.login-field {
  border-color: #8ed6ff !important;
}

.login-field:focus {
  border-color: #2979ff !important;
}

.custom-new-project-input {
  border: none;
  padding-left: 0;
  font-size: 1.5rem;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  border-color: #ced4da !important;
}

.nav-logo img {
  max-width: 134px;
  max-height: 48px;
}

.go-back-link {
  margin-bottom: 10px;
  display: inline-block;
  color: #000000;
}

.form-group.row .col-form-label {
  text-align: right;
  padding-top: calc(0.6rem + 1px);
  font-size: 14px;
}

.custom-form-control {
  background-color: #f0f0f0;
  border-color: transparent !important;
  padding: 0.4rem 0.75rem;
  font-size: 0.9rem;
  color: #333333;
}

.custom-form-control:focus {
  border-color: #e0e0e0 !important;
  background: #f0f0f0 !important;
}

.custom-form-control-dropdown {
  background-color: #f0f0f0 !important;
  width: 100%;
  border-color: transparent !important;
  padding: 0.4rem 0.75rem;
  font-size: 0.9rem;
  color: #333333;
}

.custom-form-control-dropdown:focus {
  border-color: #e0e0e0 !important;
  background: #f0f0f0 !important;
}

.form-control:disabled,
.form-control:read-only {
  background-color: #f0f0f0;
}

.password-visibility {
  position: relative;
}

.password-visibility .show-hide-password-button {
  position: absolute;
  top: 14px;
  right: 25px;
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
}

.password-visibility .show-hide-password-button svg {
  vertical-align: baseline;
}

.password-visibility .show-hide-password-button:hover svg {
  fill: blue;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67vh;
}

.css-1szzzam-menuItem-menuItemActive {
  background: #2979ff !important;
  color: #ffffff !important;
}

.css-1szzzam-menuItem-menuItemActive:hover {
  background: #0960f8 !important;
}

.css-1k8n018-menuItem:hover {
  background: #ffffff !important;
}

.user-account-info-popup {
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  outline: none;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 45px;
  -webkit-animation: gb__a 0.2s;
          animation: gb__a 0.2s;
  border-radius: 2px;
  -webkit-user-select: text;
  z-index: 1001;
  min-width: 227px;
  padding: 1rem 1rem;
  text-align: center;
  border-radius: 5px;
}

.user-info-wrapper h6 {
  color: #202124;
  letter-spacing: 0.29px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-info-wrapper p {
  color: #5f6368;
  letter-spacing: normal;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  margin-bottom: 5px;
}

.confirm-action-dialog .modal-header svg {
  font-size: 2.5rem;
  color: #dc3545;
}

.confirm-action-dialog .modal-header {
  display: block;
  text-align: center;
}

.confirm-action-dialog .modal-header h4 {
  margin-top: 1rem;
  color: #dc3545;
  font-size: 20px;
  font-weight: 500;
}

.confirm-action-dialog .modal-body {
  background-color: #f8f8f8;
}

.confirm-action-dialog .modal-body .confirm-dialog-meg {
  margin: 1rem 0 1.5rem 0;
  text-align: center;
}

.action-buttons-center button {
  margin: 0 5px;
  min-width: 90px;
}

.action-buttons-center {
  text-align: center;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: none;
  border: none;
}

.no-projects {
  color: grey;
  height: 36px;
}

.no-projects .new-project-button {
  background-color: transparent;
  border: 0;
}

.no-projects .new-project-button svg {
  vertical-align: -2px;
  margin-right: 2px;
}

.no-projects .new-project-button:hover {
  text-decoration: underline;
}

.projects-loading-spinner {
  padding-left: 3rem;
  padding-right: 3rem;
}

.nav-icon-project-select-box > .dropdown .dropdown-toggle {
  color: white;
  border-color: #3c64a7;
  border-width: 2px;
  background: #4f4f4f;
  font-weight: 600;
  min-width: 100px;
}

.nav-icon-project-select-box > .dropdown .dropdown-toggle::after {
  margin-left: 1.255em;
}

.un-selected-item {
  color: #666666;
}

.selected-item {
  color: #333333;
}

.nav-hr {
  color: white;
  /* margin: 0 15% 0 14.5%; */
  max-width: 1312px;
  margin: auto;
}

.nav-bar-main-div {
  background: #1d2125;
}

.page-size-selecter {
  margin-left: 5px;
}

.data-details {
  display: flex;
  justify-content: space-between;
}

.api-hr {
  color: white;
  margin: 0 5% 0 5%;
}

@media only screen and (max-width: 1400px) {
  .nav-hr {
    max-width: 1130px;
    margin: auto;
  }
}

.main-container {
  padding-right: 0;
}

@media only screen and (max-width: 1200px) {
  .nav-hr {
    max-width: 950px;
    margin: auto;
  }

  .main-container {
    padding-right: 0;
  }
}

@media only screen and (max-width: 990px) {
  .nav-hr {
    max-width: 714px;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .form-group.row .col-form-label {
    text-align: left;
  }
}

@media only screen and (max-width: 369px) {
  .user-account-info-popup {
    left: -64px;
  }

  .navbar-expand-md .main-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .nav-icon-project-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
  }

  .nav-icon-project-select-box {
    margin-top: 10px;
  }

  .navbar > .container {
    align-items: baseline;
  }

  .page-secondary-header {
    flex-direction: column;
  }
}

@media only screen and (max-width: 576px) {
  .main-container {
    padding-left: 0;
  }
}

.dash-back-btn {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #333333;
  margin-bottom: 15px;
}

.cus-content-div {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  min-height: 70vh;
}

.cus-content-div .sec-heading {
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.dash-perf-card {
  display: inline-block;
  width: 200px;
  background: #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  margin-right: 15px;
}

.dash-perf-card .title {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 5px;
}

.dash-perf-card .count {
  font-size: 40px;
  line-height: 46px;
  color: #333333;
  margin-bottom: 5px;
}

.dash-perf-card .pecentage {
  font-size: 16px;
  line-height: 18px;
  color: #219653;
  margin-bottom: 0;
}

.mode-btn-container {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

.btn-mode-active {
  background: #0bc95c;
  border: 0;
}

.btn-mode-active:hover,
.btn-mode-disabled:hover {
  background: #0bc95c;
  border: 0;
}

.btn-mode-active:focus,
.btn-mode-disabled:focus {
  background: #0bc95c;
  border: 0;
  box-shadow: none;
}

.btn-mode-disabled {
  background: #d4d4d4;
  border: 0;
}

.page-numbering {
  position: relative;
  top: 10px;
  font-size: 14px;
  margin-bottom: 15px;
}

.download-report-div {
  position: absolute;
  right: 0;
  z-index: 1;
}

.download-report-div button {
  background: #fff;
  color: #333333;
  border: 0;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  margin: 10px 0 10px 15px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.download-report-div button:hover {
  color: #208ac5;
}

.download-report-div button:focus {
  outline: 0;
}

.custom-table {
  border-spacing: 0;
  width: 96%;
  font-size: 14px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 10px;
}

.custom-table thead {
  background: #303030;
  color: #fff;
  text-transform: uppercase;
}

.custom-table thead tr th {
  font-weight: 500;
}

.custom-table tbody {
  color: #333333;
}

.custom-table tbody tr:hover {
  cursor: pointer;
  background: #efefef;
}

.custom-table th,
.custom-table td {
  margin: 0;
  padding: 0.5rem;
  vertical-align: middle;
}

.custom-table th:last-child,
.custom-table td:last-child {
  border-right: 0;
}

.cus-pagination {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 25px;
}

.cus-pagination button.active {
  width: 91px;
  border-radius: 5px;
  margin: 5px;
  background: #208ac5;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 0;
  padding: 3px;
}

.cus-pagination button.active:hover {
  background: #085179;
}

.cus-pagination button.dissabled {
  width: 91px;
  border-radius: 5px;
  margin: 5px;
  background: #e6e6e6;
  color: #898989;
  outline: none;
  box-shadow: none;
  border: 0;
  padding: 3px;
}

.sms-detail-sec {
  font-size: 14px;
  color: #333333;
  width: 96%;
  margin: auto;
}

.sms-detail-sec .first-col {
  font-weight: 300;
  text-align: right;
  margin-bottom: 10px;
}

.sms-detail-sec div {
  overflow-wrap: break-word;
}

.sms-detail-sec .messege-content {
  background: #e5e5e5;
  border-radius: 4px;
  padding: 5px 15px;
  font-family: 'Roboto Mono', monospace;
}

.view-related-messages {
  display: block;
  font-size: 14px;
  line-height: 16px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #1a8ccc;
  margin-top: 20px;
  margin-bottom: 35px;
}

.status-callback * {
  font-size: 14px;
}

.status-callback .input-group-text {
  background-color: #3eb968;
  border: 1px solid #3eb968;
  color: #fff;
}
.status-callback .form-control {
  border-color: #e0e0e0;
}

/* pagination */
.pagination-div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 10px;
}

.pagination-div .MuiPaginationItem-page.Mui-selected {
  background-color: #075f92;
  color: #fff;
}

.pagination-div .MuiPaginationItem-page.Mui-selected:hover {
  background-color: #075f92;
}

.pagination-div .MuiPaginationItem-page:hover {
  background-color: #0000001f;
}

.pagination-div button:focus {
  outline: 0;
}

.pagination-div .MuiPaginationItem-root {
  height: 26px;
  min-width: 26px;
  line-height: 1.5rem;
  font-size: 13px;
}

/* breadcrumb */
.breadcrumb {
  margin-bottom: 10px;
}

.breadcrumb li {
  font-size: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "> ";
}

.breadcrumb-item a {
  color: rgb(48, 48, 48);
  font-weight: 400;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  color: #075f92;
  text-decoration: underline;
}

/* preloading */
.loading-container {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loading-container .spinner-border {
  color: #b1b1b1 !important;
}

/* react phone */
.react-tel-input .form-control {
  /* border: 1px solid #8ed6ff !important; */
  border-radius: 0.25rem !important;
  height: 38px !important;
  width: 100% !important;
  outline: none;
  border: none !important;
}

.react-tel-input .form-control:focus {
  /* border: 1px solid #cacaca !important; */
}

.react-tel-input .selected-flag {
  border: none !important;
}

.react-tel-input .flag-dropdown {
  height: 38px;
  background-color: #f0f0f0 !important;
  border: none !important;
}

.react-tel-input .flag-dropdown:hover {
  cursor: default !important;
}

.cus-react-tel-input-div .react-tel-input .form-control {
  border: 1px solid #f3554f !important;
}

.cus-react-tel-input-div .react-tel-input .flag-dropdown {
  border: 1px solid #f3554f !important;
}

.header-div {
  display: flex;
  justify-content: space-between;
}

.header-div-left {
  text-align: left;
}

.test-hr {
  color: #ef6c00;
  height: 2px !important;
  margin-bottom: -4px;
  margin-top: 0;
}

.live-hr {
  height: 2px !important;
  margin-bottom: -4px;
  color: #c7c8c9;
  margin-top: 0;
}

.test-label {
  color: white;
  background: #ef6c00;
  font-weight: normal;
}

.live-label {
  color: white;
  background: #c7c8c9;
}

.main-header-div {
  text-align: center;
}

.switch-label-test {
  color: #ef6c00;
}

.switch-label-live {
  color: #333333;
}

.page-header-drop-downs {
  display: flex;
}

.page-secondary-header {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

@media only screen and (max-width: 767px) {
  .container-fluid {
    height: 52vh !important;
  }

  .custom-table {
    width: -webkit-max-content !important;
    width: max-content !important;
  }
}

@media only screen and (max-width: 850px) {
  .stat-graph-div {
    width: 49% !important;
  }
}

@media only screen and (max-width: 500px) {
  .stat-graph-div {
    width: 97% !important;
  }
}

.dashboard-date-picker {
  border: none;
  font-weight: 500;
  width: 120px;
  text-align: center;
}

.dashboard-date-picker:focus {
  border: none;
}

.dashboard-stat-date-picker {
  border: none;
  font-weight: 500;
  width: 120px;
  text-align: center;
}

.dashboard-stat-date-picker:focus {
  border: none;
}

.stat-graph-div {
  width: 32.5%;
}
.form-wrapper {
  margin-top: 2.5rem;
}

.settings-subtitle {
  padding-left: 17%;
  margin-top: 1rem;
  color: #949698;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.settings-credentials-info {
  padding-left: 26%;
  margin-bottom: 1rem;
}

.settings-credentials-info h6 {
  font-weight: 400;
  font-size: 15px;
  color: #333333;
}

.settings-credentials-info a {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.settings-credentials-info a svg {
  font-size: 16px;
  margin-left: 5px;
}

.form-wrapper .form-group {
  margin-bottom: 1rem;
}

.input-action-btn {
  color: #2979ff;
  cursor: pointer;
  margin-top: 4px;
  text-decoration: underline;
  display: flex;
  justify-content: right;
  font-size: 14px;
}

.input-action-btn:hover {
  color: blue;
}

.password-visibility .form-control {
  padding-right: 2.5rem;
}

.input-action-buttons {
  float: right;
}

.input-action-buttons button {
  margin-left: 5px;
  padding: 3px 6px;
  margin-top: 1px;
}

.input-action-buttons button svg {
    width: 1.5em;
    height: 1.5em;
}

.api-key-copy-button button {
  font-size: 15px;
}

.api-key-copy-button button svg {
  vertical-align: -3px;
  margin-right: 2px;
}

.api-key-copy-button {
  display: none;
  position: absolute;
  right: calc(var(--bs-gutter-x)/ 2);
}

svg.api-copied {
  fill: #90ee90;
}

.password-visibility:hover .api-key-copy-button{
  display: inline-block;
}

/* Settings page */

.settingButtonSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.setButton {
  width: 20%;
}


.table-header-bg {
  background-color: #e0e0e0;
}

.table-header-bg tr th {
  font-weight: 500;
  font-size: 14px;
}

.sms-custom-table > tbody {
  vertical-align: inherit;
  font-size: 14px;
}

.active-session-text {
  color: #6c757d;
}

.logout-sessions-wrapper {
  text-align: right;
}

.login-sessions-main-wrapper {
  justify-content: space-between;
}

.nav-account-btn {
  background: transparent;
  border: 0;
  color: #666666;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding-left: 18px;
}

.nav-account-btn:hover {
  background: #e9ecef;
  width: 100%;
  text-align: left;
  color: #1e2125;
}

.nav-account-btn:focus {
  outline: 0;
  box-shadow: none;
}

.project-role-text{
  text-transform: lowercase;
}

.project-role-text:first-letter {
  text-transform: uppercase;
}

.add-label {
  color: #807d7d;
}

.add-label-div {
  text-align: right;
  padding-right: 4%;
}

.preview-btn {
  width: 90px;
}

.save-btn {
  width: 90px;
  margin-left: 4px;
}

.error-label {
  color: #dc3545;
}

.create-btn-div {
  position: inherit;
  text-align: right;
}

.select-form-control {
  background: #f0f0f0 !important;
  font-size: 0.9rem;
  color: #333333;
  background: url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_arrow_drop_down_48px-24.png) no-repeat right #f0f0f0 !important;
  background-position-x: calc( 100% - 5px ) !important;
}

.page-secondory-header {
  display: flex;
  justify-content: space-between;
}

.MuiFormControlLabel-label {
  margin-right: 11px !important;
  margin-top: 3px !important;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-right: 2px !important;
}

.new-campaign-select {
  background: url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_arrow_drop_down_48px-24.png) no-repeat right #f0f0f0 !important;
  background-position-x: calc( 100% - 5px ) !important;
}

.desc-title {
  margin-top: 22px;
  font-weight: 425;
  font-size: 16px;
}

.desc-div {
  font-size: 14px;
  margin-top: 5px;
}

.id-type-desc {
  font-size: 14px;
  margin-left: 25px;
  font-weight: lighter;
}

.sending-time-drop-down:focus {
  border-color: #e0e0e0;
  box-shadow: none;
  outline: 0;
}

.sending-time-drop-down {
  padding: 0.4rem 0.75rem;
  font-size: 14px;
}
.billing-main-div {
  display: flex;
  flex-direction: row;
  width: 1312px;
  margin: auto;
}

.billing-menu-div {
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  outline: none;
  overflow: hidden;
  position: inherit;
  -webkit-animation: gb__a 0.2s;
          animation: gb__a 0.2s;
  border-radius: 2px;
  -webkit-user-select: text;
  z-index: 1000;
  min-width: 160px;
  padding: 1rem 1rem;
  padding-left: 0;
  padding-right: 0;
  border-radius: 5px;
  margin-left: 15px;
  max-height: 192px;
  width: 15%;
}

.overview-det-div {
  background: #F2F2F2;
  border-radius: 4px;
  padding: 5px 15px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.make-payment-btn {
  width: 200px;
}

.usage-summary-heading {
  font-weight: 400;
  margin-bottom: 0;
}

.customized-row {
  background: #F2F2F2;
  border-color: white;
  border-width: 3px;
}

.billing-small-label {
  font-size: 14px;
  color: #4F4F4F;
}

.current-balance {
  color: #219653;
  font-weight: bold;
}

.selected-nav-biling-btn {
  background: transparent;
  border: 0;
  color: #1e2125;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding-left: 18px;
  font-weight: 500;
}

.selected-nav-biling-btn:hover {
  background: #e9ecef;
  width: 100%;
  text-align: left;
  color: #1e2125;
}

.settings-title {
  padding-left: 17%;
  margin-top: 3rem;
  color: #949698;
  margin-bottom: 0;
}

.cancel-btn {
  background: transparent;
  color: #2979ff;
  border-color: #2979ff;
  border-style: solid;
  border-width: 1px;
}

.card-no:before {
  content: "**** **** **** ";
}

@media only screen and (max-width: 1400px) {
  .billing-main-div {
    max-width: 1130px;
  }
}

@media only screen and (max-width: 1200px) {
  .billing-main-div {
    max-width: 950px;
  }  
}

@media only screen and (max-width: 990px) {
  .billing-main-div {
    max-width: 714px;
  }
}

@media only screen and (max-width: 760px) {
  .billing-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .billing-menu-div {
    width: 100%;
    margin-bottom: 4%;
  }

  .billing-cus-container {
    margin: auto !important;
  }
}
.api-main-div {
  display: flex;
  flex-direction: row;
  background: #1D2125;
}

.api-menu-div {
  background: #1D2125;
  border-color: rgba(0, 0, 0, 0.2);
  color: #FFFF;
  outline: none;
  -webkit-animation: gb__a 0.2s;
          animation: gb__a 0.2s;
  border-radius: 2px;
  -webkit-user-select: text;
  min-width: 160px;
  padding: 1rem 1rem;
  width: 12%;
  border-right-color: #4F4F4F;
  margin-top: 38px;
  border-right-width: 1px;
  border-right-style: solid;
  margin-left: 5%;
}

.api-cus-container {
  background: #1D2125;
  width: 88%;
}

.api-cus-content-div {
  background: #1D2125;
  border-radius: 0;
  color: #FFFF;
  height: 89vh;
  overflow-y: scroll;
}

.api-det-full-div {
  display: flex;
}

.api-det-div {
  width: 50%;
}

.api-sample-div {
  width: 50%;
  padding-left: 47px;
}

.api-heading {
  font-weight: 400;
  margin-bottom: 30px;
  padding-top: 30px;
}

.sub-api-title {
  margin-left: 10px;
  text-align: left;
}

.api-title {
  font-weight: 500;
  color: #BDBDBD;
}

.method-example-response-title {
  background: #37474f;
  color: #a7aeb2;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.method-example-response {
  background: #263238;
  border-radius: 7px;
  font-family: monospace;
}

.method-example-response-title {
  border-radius: inherit;
}

.method-example-response-topbar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.method-response-content {
  color: #a7aeb2;
  padding: 10px;
}

.method-example-2-response {
  margin-top: 30px;
  background: #263238;
  border-radius: 7px;
  font-family: monospace;
}

.method-example-2-response-title {
  background: #37474f;
  color: #a7aeb2;
  padding: 5px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.method-response-2-content {
  color: #a7aeb2;
  padding: 10px;
}

.method-example-2-response-topbar {
  border-radius: inherit;
}

.cus-container {
  margin-left: 20%;
}

.post-label {
  color: white;
  background: #ef6c00;
  font-weight: normal;
}

.get-label {
  background: #219653;
  color: white;
  font-weight: normal;
}

.api-request-heading {
  margin-bottom: 0;
  color: #a7aeb2;
}

.api-description-p {
  color: #7a7c7f;
}

.parameter-description-p {
  color: #7a7c7f;
}

.api-response-description-p {
  color: #7a7c7f;
}

.active-menu {
  color: white;
}

.curl-link {
  color: #767878;
}

.api-sub-heading-hr {
  margin-top: 0;
}

.api-sub-heading {
  margin-bottom: 0;
  margin-top: 34px;
}

.api-parameter-title {
  color: #FFFFFF;
  font-weight: lighter;
}

.copy-icon {
  margin-top: auto;
  cursor: pointer;
  margin-bottom: auto;
}

.api-intro-det-div {
  width: 100%;
  text-align: justify;
}

.error-code-table {
  color: #7a7c7f;
}

.error-code-table-header {
  color: #FFFFFF;
}

.full-response {
  width: 39%;
  margin-top: 2%;
  margin-bottom: 2%;
}

svg.api-copied {
  fill: #90ee90;
}

.copied-div {
  display: none;
  justify-content: end;
  align-items: center;
  font-size: 12px;
  position: absolute;
}

.error-code-row {
  /* border-top-color: #414346;
  border-top-width: 1px;
  border-top-style: 1px; */
}

@media only screen and (max-width: 850px) {
  .api-menu-div {
    display: none;
  }

  .cus-container {
    margin-left: 0;
  }

  .api-det-full-div {
    display: flex;
    flex-direction: column;
  }

  .api-sample-div {
    width: 100%;
    padding-left: 0;
  }

  .api-det-div {
    width: 100%;
  }

  .method-response-2-content {
    overflow-x: scroll;
  }

  .full-response {
    width: 100%;
  }

  .api-cus-container {
    width: 100%;
  }

  .api-cus-content-div {
    height: 19vh;
  }
}

@media only screen and (max-width: 600px) {
  .api-nav-main-div {
    display: flex;
    flex-direction: column;
  }

  .api-navigation-icon-wrapper {
    justify-content: center;
  }

  .api-main-nav-links-wrapper {
    justify-content: center;
    margin-top: 20px;
  }

  .api-cus-content-div {
    height: 19vh;
  }
}

.error-code-table td {
  /* border-color: #414346;
  border-width: 1px;
  border-style: 1px;*/
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.error-code-table .even-row {
  background: #263238;
}
body {
  background: #d9effb;
}

.form-heading {
  font-weight: 500;
  color: #333333;
  padding-left: 0.5rem;
  margin-top: 15px;
}

.form-sub-heading {
  font-size: 12px;
  color: #333333;
  font-weight: 300;
  padding-left: 0.5rem;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333333;
}

.required-tag {
  color: #dc3545;
}

.cus-error {
  color: #dc3545;
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.875em;
}

.form-control {
  box-shadow: none;
  border-color: transparent;
}

.rbt-input-multi.focus {
  border-color: #e0e0e0 !important;
  box-shadow: none;
}

.form-control:focus {
  border-color: #e0e0e0;
  box-shadow: none;
  outline: 0;
}

.custom-file-input {
  box-shadow: none;
  border-color: #8f8f8f;
}

.custom-file-input:focus {
  border-color: #030b21;
  box-shadow: none;
  outline: 0;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #fff no-repeat calc(100% - 5px) !important;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.back-btn {
  display: block;
  font-size: 14px;
  color: #969696;
}

.back-btn:hover {
  color: #646464;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  background: transparent;
  border: none;
  color: #828282;
}

.btn-outline-secondary:hover {
  background: #EAEAEA;
  color: #828282;
}

.modal-close-btn {
  height: 25px;
  width: 25px;
  color: #333333;
  padding: 5px;
}

.modal-close-btn:hover {
  background: #EAEAEA;
  border-radius: 2px;
}

.modal-title {
  font-size: 20px;
  color: #333333;
}

.modal-hr {
  margin-bottom: 10px;
  margin-top: 5px;
}

.modal-input {
  background: #f0f0f0;
  font-size: 14px;
}

.modal-input:focus {
  background: #f0f0f0;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 2.5rem;
}

.react-tel-input .selected-flag:hover {
  background-color: #f0f0f0;
}

.react-tel-input .selected-flag:focus {
  background-color: #f0f0f0;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #f0f0f0;
}

.modal-header {
  padding-bottom: 0;
}

.page-drop-down {
  border-color: #4F4F4F;
  border-style: solid;
  border-width: 1px;
  color: #333333;
  font-weight: 500;
}

.without-hover-table tbody tr:hover {
  background: transparent;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.text-muted {
  display: flex;
  justify-content: right;
}

/* Bulk upload modal */
.bulk_contact_modal {
  overflow-y: visible;
  overflow-y: initial;
}

.bulk_modal {
  max-height: 60vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.upload-box {
  display: flex;
  justify-content: center;
}

.bulk_modal_subheading {
  font-size: 14px;
  font-weight: 400;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.dragDrop {
  font-size: 1rem;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #828282;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 1.2rem;
  font-family: 'Oswald', sans-serif;
  background-color: #BDBDBD;
}

.upload-button:hover {
  cursor: pointer;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.csv-warning {
  margin-left: 3%;
  font-size: 12px;
}

/* bulk contact preview table */
.preview_table {
  border: 0.5px solid #AEB6BF;
  border-collapse: collapse;
  text-align: center;
  font-size: 0.9em;

}

.preview_td {
  border: 1px solid;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.preview_th {
  border: 1px solid;
  text-transform: uppercase;
}

/* contact page pagination */
.page-secondory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-btn-div {
  position: inherit;
  text-align: right;
}

.page-numbering-contact {
  position: relative;
  font-size: 14px;
  margin-top: 1rem;
  bottom: 0px;
}

.contact-delete-btn {
  margin-top: 1rem;
}

.data-details-contact {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
}


