.api-main-div {
  display: flex;
  flex-direction: row;
  background: #1D2125;
}

.api-menu-div {
  background: #1D2125;
  border-color: rgba(0, 0, 0, 0.2);
  color: #FFFF;
  outline: none;
  animation: gb__a 0.2s;
  border-radius: 2px;
  -webkit-user-select: text;
  min-width: 160px;
  padding: 1rem 1rem;
  width: 12%;
  border-right-color: #4F4F4F;
  margin-top: 38px;
  border-right-width: 1px;
  border-right-style: solid;
  margin-left: 5%;
}

.api-cus-container {
  background: #1D2125;
  width: 88%;
}

.api-cus-content-div {
  background: #1D2125;
  border-radius: 0;
  color: #FFFF;
  height: 89vh;
  overflow-y: scroll;
}

.api-det-full-div {
  display: flex;
}

.api-det-div {
  width: 50%;
}

.api-sample-div {
  width: 50%;
  padding-left: 47px;
}

.api-heading {
  font-weight: 400;
  margin-bottom: 30px;
  padding-top: 30px;
}

.sub-api-title {
  margin-left: 10px;
  text-align: left;
}

.api-title {
  font-weight: 500;
  color: #BDBDBD;
}

.method-example-response-title {
  background: #37474f;
  color: #a7aeb2;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.method-example-response {
  background: #263238;
  border-radius: 7px;
  font-family: monospace;
}

.method-example-response-title {
  border-radius: inherit;
}

.method-example-response-topbar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.method-response-content {
  color: #a7aeb2;
  padding: 10px;
}

.method-example-2-response {
  margin-top: 30px;
  background: #263238;
  border-radius: 7px;
  font-family: monospace;
}

.method-example-2-response-title {
  background: #37474f;
  color: #a7aeb2;
  padding: 5px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.method-response-2-content {
  color: #a7aeb2;
  padding: 10px;
}

.method-example-2-response-topbar {
  border-radius: inherit;
}

.cus-container {
  margin-left: 20%;
}

.post-label {
  color: white;
  background: #ef6c00;
  font-weight: normal;
}

.get-label {
  background: #219653;
  color: white;
  font-weight: normal;
}

.api-request-heading {
  margin-bottom: 0;
  color: #a7aeb2;
}

.api-description-p {
  color: #7a7c7f;
}

.parameter-description-p {
  color: #7a7c7f;
}

.api-response-description-p {
  color: #7a7c7f;
}

.active-menu {
  color: white;
}

.curl-link {
  color: #767878;
}

.api-sub-heading-hr {
  margin-top: 0;
}

.api-sub-heading {
  margin-bottom: 0;
  margin-top: 34px;
}

.api-parameter-title {
  color: #FFFFFF;
  font-weight: lighter;
}

.copy-icon {
  margin-top: auto;
  cursor: pointer;
  margin-bottom: auto;
}

.api-intro-det-div {
  width: 100%;
  text-align: justify;
}

.error-code-table {
  color: #7a7c7f;
}

.error-code-table-header {
  color: #FFFFFF;
}

.full-response {
  width: 39%;
  margin-top: 2%;
  margin-bottom: 2%;
}

svg.api-copied {
  fill: #90ee90;
}

.copied-div {
  display: none;
  justify-content: end;
  align-items: center;
  font-size: 12px;
  position: absolute;
}

.error-code-row {
  /* border-top-color: #414346;
  border-top-width: 1px;
  border-top-style: 1px; */
}

@media only screen and (max-width: 850px) {
  .api-menu-div {
    display: none;
  }

  .cus-container {
    margin-left: 0;
  }

  .api-det-full-div {
    display: flex;
    flex-direction: column;
  }

  .api-sample-div {
    width: 100%;
    padding-left: 0;
  }

  .api-det-div {
    width: 100%;
  }

  .method-response-2-content {
    overflow-x: scroll;
  }

  .full-response {
    width: 100%;
  }

  .api-cus-container {
    width: 100%;
  }

  .api-cus-content-div {
    height: 19vh;
  }
}

@media only screen and (max-width: 600px) {
  .api-nav-main-div {
    display: flex;
    flex-direction: column;
  }

  .api-navigation-icon-wrapper {
    justify-content: center;
  }

  .api-main-nav-links-wrapper {
    justify-content: center;
    margin-top: 20px;
  }

  .api-cus-content-div {
    height: 19vh;
  }
}

.error-code-table td {
  /* border-color: #414346;
  border-width: 1px;
  border-style: 1px;*/
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.error-code-table .even-row {
  background: #263238;
}