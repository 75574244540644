.table-header-bg {
  background-color: #e0e0e0;
}

.table-header-bg tr th {
  font-weight: 500;
  font-size: 14px;
}

.sms-custom-table > tbody {
  vertical-align: inherit;
  font-size: 14px;
}

.active-session-text {
  color: #6c757d;
}

.logout-sessions-wrapper {
  text-align: right;
}

.login-sessions-main-wrapper {
  justify-content: space-between;
}

.nav-account-btn {
  background: transparent;
  border: 0;
  color: #666666;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding-left: 18px;
}

.nav-account-btn:hover {
  background: #e9ecef;
  width: 100%;
  text-align: left;
  color: #1e2125;
}

.nav-account-btn:focus {
  outline: 0;
  box-shadow: none;
}

.project-role-text{
  text-transform: lowercase;
}

.project-role-text:first-letter {
  text-transform: uppercase;
}
