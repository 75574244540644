.billing-main-div {
  display: flex;
  flex-direction: row;
  width: 1312px;
  margin: auto;
}

.billing-menu-div {
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  outline: none;
  overflow: hidden;
  position: inherit;
  animation: gb__a 0.2s;
  border-radius: 2px;
  -webkit-user-select: text;
  z-index: 1000;
  min-width: 160px;
  padding: 1rem 1rem;
  padding-left: 0;
  padding-right: 0;
  border-radius: 5px;
  margin-left: 15px;
  max-height: 192px;
  width: 15%;
}

.overview-det-div {
  background: #F2F2F2;
  border-radius: 4px;
  padding: 5px 15px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.make-payment-btn {
  width: 200px;
}

.usage-summary-heading {
  font-weight: 400;
  margin-bottom: 0;
}

.customized-row {
  background: #F2F2F2;
  border-color: white;
  border-width: 3px;
}

.billing-small-label {
  font-size: 14px;
  color: #4F4F4F;
}

.current-balance {
  color: #219653;
  font-weight: bold;
}

.selected-nav-biling-btn {
  background: transparent;
  border: 0;
  color: #1e2125;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding-left: 18px;
  font-weight: 500;
}

.selected-nav-biling-btn:hover {
  background: #e9ecef;
  width: 100%;
  text-align: left;
  color: #1e2125;
}

.settings-title {
  padding-left: 17%;
  margin-top: 3rem;
  color: #949698;
  margin-bottom: 0;
}

.cancel-btn {
  background: transparent;
  color: #2979ff;
  border-color: #2979ff;
  border-style: solid;
  border-width: 1px;
}

.card-no:before {
  content: "**** **** **** ";
}

@media only screen and (max-width: 1400px) {
  .billing-main-div {
    max-width: 1130px;
  }
}

@media only screen and (max-width: 1200px) {
  .billing-main-div {
    max-width: 950px;
  }  
}

@media only screen and (max-width: 990px) {
  .billing-main-div {
    max-width: 714px;
  }
}

@media only screen and (max-width: 760px) {
  .billing-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .billing-menu-div {
    width: 100%;
    margin-bottom: 4%;
  }

  .billing-cus-container {
    margin: auto !important;
  }
}