body {
  background: #d9effb;
}

.form-heading {
  font-weight: 500;
  color: #333333;
  padding-left: 0.5rem;
  margin-top: 15px;
}

.form-sub-heading {
  font-size: 12px;
  color: #333333;
  font-weight: 300;
  padding-left: 0.5rem;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333333;
}

.required-tag {
  color: #dc3545;
}

.cus-error {
  color: #dc3545;
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.875em;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}

.rbt-input-multi.focus {
  border-color: #e0e0e0 !important;
  box-shadow: none;
}

.form-control:focus {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.custom-file-input {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #8f8f8f;
}

.custom-file-input:focus {
  border-color: #030b21;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #fff no-repeat calc(100% - 5px) !important;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.back-btn {
  display: block;
  font-size: 14px;
  color: #969696;
}

.back-btn:hover {
  color: #646464;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  background: transparent;
  border: none;
  color: #828282;
}

.btn-outline-secondary:hover {
  background: #EAEAEA;
  color: #828282;
}

.modal-close-btn {
  height: 25px;
  width: 25px;
  color: #333333;
  padding: 5px;
}

.modal-close-btn:hover {
  background: #EAEAEA;
  border-radius: 2px;
}

.modal-title {
  font-size: 20px;
  color: #333333;
}

.modal-hr {
  margin-bottom: 10px;
  margin-top: 5px;
}

.modal-input {
  background: #f0f0f0;
  font-size: 14px;
}

.modal-input:focus {
  background: #f0f0f0;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 2.5rem;
}

.react-tel-input .selected-flag:hover {
  background-color: #f0f0f0;
}

.react-tel-input .selected-flag:focus {
  background-color: #f0f0f0;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #f0f0f0;
}

.modal-header {
  padding-bottom: 0;
}

.page-drop-down {
  border-color: #4F4F4F;
  border-style: solid;
  border-width: 1px;
  color: #333333;
  font-weight: 500;
}

.without-hover-table tbody tr:hover {
  background: transparent;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.text-muted {
  display: flex;
  justify-content: right;
}

/* Bulk upload modal */
.bulk_contact_modal {
  overflow-y: initial;
}

.bulk_modal {
  max-height: 60vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.upload-box {
  display: flex;
  justify-content: center;
}

.bulk_modal_subheading {
  font-size: 14px;
  font-weight: 400;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.dragDrop {
  font-size: 1rem;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #828282;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 1.2rem;
  font-family: 'Oswald', sans-serif;
  background-color: #BDBDBD;
}

.upload-button:hover {
  cursor: pointer;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.csv-warning {
  margin-left: 3%;
  font-size: 12px;
}

/* bulk contact preview table */
.preview_table {
  border: 0.5px solid #AEB6BF;
  border-collapse: collapse;
  text-align: center;
  font-size: 0.9em;

}

.preview_td {
  border: 1px solid;
  min-width: fit-content;
}

.preview_th {
  border: 1px solid;
  text-transform: uppercase;
}

/* contact page pagination */
.page-secondory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-btn-div {
  position: inherit;
  text-align: right;
}

.page-numbering-contact {
  position: relative;
  font-size: 14px;
  margin-top: 1rem;
  bottom: 0px;
}

.contact-delete-btn {
  margin-top: 1rem;
}

.data-details-contact {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
}

