.add-label {
  color: #807d7d;
}

.add-label-div {
  text-align: right;
  padding-right: 4%;
}

.preview-btn {
  width: 90px;
}

.save-btn {
  width: 90px;
  margin-left: 4px;
}

.error-label {
  color: #dc3545;
}

.create-btn-div {
  position: inherit;
  text-align: right;
}

.select-form-control {
  background: #f0f0f0 !important;
  font-size: 0.9rem;
  color: #333333;
  background: url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_arrow_drop_down_48px-24.png) no-repeat right #f0f0f0 !important;
  background-position-x: calc( 100% - 5px ) !important;
}

.page-secondory-header {
  display: flex;
  justify-content: space-between;
}

.MuiFormControlLabel-label {
  margin-right: 11px !important;
  margin-top: 3px !important;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-right: 2px !important;
}

.new-campaign-select {
  background: url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_arrow_drop_down_48px-24.png) no-repeat right #f0f0f0 !important;
  background-position-x: calc( 100% - 5px ) !important;
}

.desc-title {
  margin-top: 22px;
  font-weight: 425;
  font-size: 16px;
}

.desc-div {
  font-size: 14px;
  margin-top: 5px;
}

.id-type-desc {
  font-size: 14px;
  margin-left: 25px;
  font-weight: lighter;
}

.sending-time-drop-down:focus {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.sending-time-drop-down {
  padding: 0.4rem 0.75rem;
  font-size: 14px;
}