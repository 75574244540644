.logo-col {
  background-color: #040d21;
}

.login-reg-col {
  background: #fff;
}

.logo-col .logo-back-div {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo-col .logo-back-div .logo {
  display: block;
  width: 175px;
  margin: auto;
  margin-bottom: 30px;
}

.logo-col .logo-back-div .heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 35px;
  color: #e8f0fe;
  letter-spacing: 0.7px;
}

.logo-col .feature-table {
  width: 345px;
  margin: auto;
}

.logo-col .feature-table tr td {
  padding: 10px;
  font-size: 18px;
}

.logo-col .feature-table tr td:nth-child(even) {
  color: #e8f0fe;
}

.logo-col,
.login-reg-col {
  height: 100vh;
  position: relative;
}

.logo-col .feature-table .feature-icon {
  font-size: 25px;
  color: #32acf1;
  vertical-align: middle;
}

@media (max-width: 380px) {
  .logo-col .feature-table {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .logo-col {
    height: 570px;
    order: 2;
  }

  .login-reg-col {
    min-height: 530px;
    height: unset;
    order: 1;
  }
}

@media (max-width: 1200px) {
  .logo-col .logo-back-div {
    width: 90%;
  }
}

.login-reg-container {
  width: 75%;
  max-width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-reg-container .heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  color: #033d5e;
  letter-spacing: 0.7px;
  padding: 0 15px;
}

@media (max-width: 480px) {
  .login-reg-container {
    width: 90%;
  }
}

.btn-submit {
  display: block;
  width: 100%;
  background: #2979FF;
  padding: 7px 15px;
  color: #fff;
  text-align: center;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 25px;
  font-weight: 500;
}

.btn-delete {
  display: block;
  width: 100%;
  background: #dc3545;
  padding: 7px 15px;
  color: #fff;
  text-align: center;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 25px;
  font-weight: 500;
}

.btn-submit:hover {
  background: #075f92;
  color: #fff;
}

.btn-submit:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.btn-cancel {
  display: block;
  width: 100%;
  background: transparent;
  padding: 6px 15px;
  color: #4c4c4c;
  text-align: center;
  border-width: 1px;
  border-color: #2979FF;
  border-radius: 0.25rem;
  margin-top: 25px;
  font-weight: 500;
}

.btn-cancel:hover {
  background: #2979FF;
  color: #fff;
}

.btn-cancel:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.form-bellow-link {
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.forget-password-link {
  display: block;
  font-size: 13px;
  text-align: center;
  margin-top: 15px;
  color: #1891d6;
  text-decoration: none;
}

.login-now-link {
  color: #1891d6;
  font-weight: 400;
  text-decoration: none;
}

.forget-password-link:hover,
.login-now-link:hover {
  color: #095f91;
  text-decoration: underline;
}

.forget-password-steps {
  color: #208ac5;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  display: block;
}

/* Login modal */
.lg-mdl-description {
  font-size: 1rem;
}

.auth-error {
  font-size: 0.9rem;
  color: #dc3545;
}