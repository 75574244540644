.dash-back-btn {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #333333;
  margin-bottom: 15px;
}

.cus-content-div {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  min-height: 70vh;
}

.cus-content-div .sec-heading {
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.dash-perf-card {
  display: inline-block;
  width: 200px;
  background: #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  margin-right: 15px;
}

.dash-perf-card .title {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 5px;
}

.dash-perf-card .count {
  font-size: 40px;
  line-height: 46px;
  color: #333333;
  margin-bottom: 5px;
}

.dash-perf-card .pecentage {
  font-size: 16px;
  line-height: 18px;
  color: #219653;
  margin-bottom: 0;
}

.mode-btn-container {
  display: block;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

.btn-mode-active {
  background: #0bc95c;
  border: 0;
}

.btn-mode-active:hover,
.btn-mode-disabled:hover {
  background: #0bc95c;
  border: 0;
}

.btn-mode-active:focus,
.btn-mode-disabled:focus {
  background: #0bc95c;
  border: 0;
  box-shadow: none;
}

.btn-mode-disabled {
  background: #d4d4d4;
  border: 0;
}

.page-numbering {
  position: relative;
  top: 10px;
  font-size: 14px;
  margin-bottom: 15px;
}

.download-report-div {
  position: absolute;
  right: 0;
  z-index: 1;
}

.download-report-div button {
  background: #fff;
  color: #333333;
  border: 0;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  margin: 10px 0 10px 15px;
  text-decoration-line: underline;
}

.download-report-div button:hover {
  color: #208ac5;
}

.download-report-div button:focus {
  outline: 0;
}

.custom-table {
  border-spacing: 0;
  width: 96%;
  font-size: 14px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 10px;
}

.custom-table thead {
  background: #303030;
  color: #fff;
  text-transform: uppercase;
}

.custom-table thead tr th {
  font-weight: 500;
}

.custom-table tbody {
  color: #333333;
}

.custom-table tbody tr:hover {
  cursor: pointer;
  background: #efefef;
}

.custom-table th,
.custom-table td {
  margin: 0;
  padding: 0.5rem;
  vertical-align: middle;
}

.custom-table th:last-child,
.custom-table td:last-child {
  border-right: 0;
}

.cus-pagination {
  width: fit-content;
  margin: auto;
  margin-top: 25px;
}

.cus-pagination button.active {
  width: 91px;
  border-radius: 5px;
  margin: 5px;
  background: #208ac5;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 0;
  padding: 3px;
}

.cus-pagination button.active:hover {
  background: #085179;
}

.cus-pagination button.dissabled {
  width: 91px;
  border-radius: 5px;
  margin: 5px;
  background: #e6e6e6;
  color: #898989;
  outline: none;
  box-shadow: none;
  border: 0;
  padding: 3px;
}

.sms-detail-sec {
  font-size: 14px;
  color: #333333;
  width: 96%;
  margin: auto;
}

.sms-detail-sec .first-col {
  font-weight: 300;
  text-align: right;
  margin-bottom: 10px;
}

.sms-detail-sec div {
  overflow-wrap: break-word;
}

.sms-detail-sec .messege-content {
  background: #e5e5e5;
  border-radius: 4px;
  padding: 5px 15px;
  font-family: 'Roboto Mono', monospace;
}

.view-related-messages {
  display: block;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #1a8ccc;
  margin-top: 20px;
  margin-bottom: 35px;
}

.status-callback * {
  font-size: 14px;
}

.status-callback .input-group-text {
  background-color: #3eb968;
  border: 1px solid #3eb968;
  color: #fff;
}
.status-callback .form-control {
  border-color: #e0e0e0;
}

/* pagination */
.pagination-div {
  width: fit-content;
  margin: auto;
  margin-top: 10px;
}

.pagination-div .MuiPaginationItem-page.Mui-selected {
  background-color: #075f92;
  color: #fff;
}

.pagination-div .MuiPaginationItem-page.Mui-selected:hover {
  background-color: #075f92;
}

.pagination-div .MuiPaginationItem-page:hover {
  background-color: #0000001f;
}

.pagination-div button:focus {
  outline: 0;
}

.pagination-div .MuiPaginationItem-root {
  height: 26px;
  min-width: 26px;
  line-height: 1.5rem;
  font-size: 13px;
}

/* breadcrumb */
.breadcrumb {
  margin-bottom: 10px;
}

.breadcrumb li {
  font-size: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "> ";
}

.breadcrumb-item a {
  color: rgb(48, 48, 48);
  font-weight: 400;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  color: #075f92;
  text-decoration: underline;
}

/* preloading */
.loading-container {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-container .spinner-border {
  color: #b1b1b1 !important;
}

/* react phone */
.react-tel-input .form-control {
  /* border: 1px solid #8ed6ff !important; */
  border-radius: 0.25rem !important;
  height: 38px !important;
  width: 100% !important;
  outline: none;
  border: none !important;
}

.react-tel-input .form-control:focus {
  /* border: 1px solid #cacaca !important; */
}

.react-tel-input .selected-flag {
  border: none !important;
}

.react-tel-input .flag-dropdown {
  height: 38px;
  background-color: #f0f0f0 !important;
  border: none !important;
}

.react-tel-input .flag-dropdown:hover {
  cursor: default !important;
}

.cus-react-tel-input-div .react-tel-input .form-control {
  border: 1px solid #f3554f !important;
}

.cus-react-tel-input-div .react-tel-input .flag-dropdown {
  border: 1px solid #f3554f !important;
}

.header-div {
  display: flex;
  justify-content: space-between;
}

.header-div-left {
  text-align: left;
}

.test-hr {
  color: #ef6c00;
  height: 2px !important;
  margin-bottom: -4px;
  margin-top: 0;
}

.live-hr {
  height: 2px !important;
  margin-bottom: -4px;
  color: #c7c8c9;
  margin-top: 0;
}

.test-label {
  color: white;
  background: #ef6c00;
  font-weight: normal;
}

.live-label {
  color: white;
  background: #c7c8c9;
}

.main-header-div {
  text-align: center;
}

.switch-label-test {
  color: #ef6c00;
}

.switch-label-live {
  color: #333333;
}

.page-header-drop-downs {
  display: flex;
}

.page-secondary-header {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

@media only screen and (max-width: 767px) {
  .container-fluid {
    height: 52vh !important;
  }

  .custom-table {
    width: max-content !important;
  }
}

@media only screen and (max-width: 850px) {
  .stat-graph-div {
    width: 49% !important;
  }
}

@media only screen and (max-width: 500px) {
  .stat-graph-div {
    width: 97% !important;
  }
}

.dashboard-date-picker {
  border: none;
  font-weight: 500;
  width: 120px;
  text-align: center;
}

.dashboard-date-picker:focus {
  border: none;
}

.dashboard-stat-date-picker {
  border: none;
  font-weight: 500;
  width: 120px;
  text-align: center;
}

.dashboard-stat-date-picker:focus {
  border: none;
}

.stat-graph-div {
  width: 32.5%;
}